import * as React from 'react';
import { Link } from 'gatsby';
import { Container } from 'react-bootstrap';
import Layout from '../components/layout';
import Seo from '../components/seo';

const PrivacyPolicy = () => {
  return (
    <Layout>
      <Container className="my-3">
        <Seo title="プライバシーポリシー" />
        <h1 className="h2">プライバシーポリシー</h1>
        <div className="my-5">
          <h2 className="h4">個人情報の利用目的</h2>
          <div className="my-2">当ブログでは、お問い合わせの際、名前やメールアドレス等の個人情報を入力いただく場合がございます。</div>
          <div className="my-2">取得した個人情報は、お問い合わせに対する回答や必要な情報を電子メールなどをでご連絡する場合に利用させていただくものであり、これらの目的以外では利用いたしません。
          </div>
        </div>
        <div className="my-5">
          <h2 className="h4">広告について</h2>
          <div className="my-2">当ブログでは、第三者配信の広告サービス（Googleアドセンス）を利用しており、ユーザーの興味に応じた商品やサービスの広告を表示するため、クッキー（Cookie）を使用しております。</div>
          <div className="my-2">クッキーを使用することで当サイトはお客様のコンピュータを識別できるようになりますが、お客様個人を特定できるものではありません。</div>
          <div className="my-2">Cookieを無効にする方法やGoogleアドセンスに関する詳細は「<a href="https://policies.google.com/technologies/ads?gl=jp">広告 – ポリシーと規約 – Google</a>」をご確認ください。
          </div>
        </div>
        <div className="my-5">
          <h2 className="h4">アクセス解析ツールについて</h2>
          <div className="my-2">当ブログでは、Googleによるアクセス解析ツール「Googleアナリティクス」を利用しています。</div>
          <div className="my-2">このGoogleアナリティクスはトラフィックデータの収集のためにクッキー（Cookie）を使用しております。トラフィックデータは匿名で収集されており、個人を特定するものではありません。</div>
        </div>
        <div className="mt-5 text-center">
          <Link to="/" className="text-secondary text-decoration-none">記事一覧に戻る</Link>
        </div>
      </Container>
    </Layout>
  )
}

export default PrivacyPolicy;